import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      username: '',
      token: ''
    }
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo.username = userInfo.username
      state.userInfo.token = userInfo.token
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [persistedState({
    storage: window.sessionStorage,
    reducer (val) {
      return {
        // 只储存state中的token
        userInfo: val.userInfo
      }
    }
  })]
})
