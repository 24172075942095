<template>
  <div class="login-page">
    <div class="login-panel">
      <div class="from-title">营养加油站管理后台</div>
      <el-form :model="LoginFrom" :rules="LoginRules" ref="LoginRef" class="login-from">
        <el-form-item label="帐号" prop="account">
          <el-input v-model="LoginFrom.account" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="LoginFrom.password" size="medium"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center;margin-top: 40px;">
          <el-button type="primary" @click="submitForm('LoginRef')">登  录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      LoginFrom: {
        account: '',
        password: ''
      },
      LoginRules: {
        account: [
          { required: true, message: '请输入登录帐号', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/login', this.LoginFrom)
          if (res.meta.status === 200) {
            // 存储用户信息到vuex
            this.$store.commit('setUserInfo', res.data)
            await this.$router.push('/dashboard/overview')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
  .login-page{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/img/background.jpg');
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-panel{
    padding: 20px 40px;
    background-color: rgba(255,255,255,0.8);
    border-radius: 4px;
  }
  .login-from{}
  .from-title{
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    opacity: 0.1;
    margin-bottom: 20px;
  }
</style>
