import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: Login },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      { path: 'overview', name: 'overview', component: () => import('../components/Overview.vue') },
      { path: 'hospital', name: 'hospital', component: () => import('../components/hospital/HospitalDisplay.vue') },
      { path: 'hospital-add', name: 'hospital-add', component: () => import('../components/hospital/HospitalAdd.vue') },
      { path: 'hospital-edit/:id', name: 'hospital-edit', component: () => import('../components/hospital/HospitalEdit.vue') },
      { path: 'area', name: 'area', component: () => import('../components/area/AreaDisplay.vue') },
      { path: 'area-add', name: 'area-add', component: () => import('../components/area/AreaAdd.vue') },
      { path: 'area-edit/:id', name: 'area-edit', component: () => import('../components/area/AreaEdit.vue') },
      { path: 'nurse', name: 'nurse', component: () => import('../components/nurse/NurseDisplay.vue') },
      { path: 'nurse-edit/:id', name: 'nurse-edit', component: () => import('../components/nurse/NurseEdit.vue') },
      { path: 'device', name: 'device', component: () => import('../components/device/DeviceDisplay.vue') },
      { path: 'device-add', name: 'device-add', component: () => import('../components/device/DeviceAdd.vue') },
      { path: 'device-edit/:id', name: 'device-edit', component: () => import('../components/device/DeviceEdit.vue') },
      { path: 'record', name: 'record', component: () => import('../components/record/RecordDisplay.vue') },
      { path: 'article', name: 'article', component: () => import('../components/article/ArticleDisplay.vue') },
      { path: 'article-add', name: 'article-add', component: () => import('../components/article/ArticleAdd.vue') },
      { path: 'article-edit/:id', name: 'article-edit', component: () => import('../components/article/ArticleEdit.vue') },
      { path: 'video', name: 'video', component: () => import('../components/video/VideoDisplay.vue') },
      { path: 'video-add', name: 'video-add', component: () => import('../components/video/VideoAdd.vue') },
      { path: 'video-edit/:id', name: 'video-edit', component: () => import('../components/video/VideoEdit.vue') },
      { path: 'users', name: 'users', component: () => import('../components/userlist/UserListDisplay.vue') },
      { path: 'sms', name: 'sms', component: () => import('../components/setup/Sms.vue') },
      { path: 'xcx', name: 'xcx', component: () => import('../components/setup/Xcx.vue') },
      { path: 'template', name: 'template', component: () => import('../components/setup/MessageTemplate.vue') }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
